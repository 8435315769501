import React from 'react'
import { Animated } from 'react-animated-css'
import about_img from '../icons/about.jpg'

function About() {
   return (
      <div className="about">
         <div class="container">
            <div class="row align-items-center my-5">
               <div class="col-lg-6">
                  <Animated
                     animationIn="bounceInLeft"
                     animationOut="fadeOut"
                     isVisible={true}
                  >
                     <h2 class="font-weight-light"> Über uns</h2>
                     <p>
                        Die
                        <a style={{ fontStyle: 'italic' }}>
                           {' '}
                           CE-SYS GmbH Computer & Elektronik Systemhaus
                        </a>
                        &nbsp;hat sich seit Ihrer Gründung im Jahr 1990 zu einem
                        kompetenten Ansprechpartner für alle Fragen und Lösungen
                        rund um die Themen:
                     </p>
                     <ul>
                        <li>IT-Infrastruktur</li>
                        <li>Hard- & Software</li>
                        <li>IT-Security</li>
                        <li>IT-Support</li>
                        <li>Informationssicherheit</li>
                     </ul>
                     <p>entwickelt.</p>
                     <p>
                        Unser Leistungsangebot ist herstellerunabhängig und
                        breit gefächert. Dadurch ist es uns möglich, auf die
                        individuellen Bedürfnisse unserer Kunden einzugehen und
                        spezielle, auf sie zugeschnittene und optimierte
                        Leistungsangebote zu offerieren.
                     </p>
                  </Animated>
               </div>
               <div class="col-lg-6">
                  <Animated
                     animationIn="slideInRight"
                     animationOut="slideOutRight"
                     animationInDuration={1000}
                     animationOutDuration={1000}
                     isVisible={true}
                  >
                     <a
                        href="http://www.freepik.com/vectorjuice"
                        target="_blank"
                        rel="noopener noreferrer"
                     >
                        <img
                           src={about_img}
                           alt="about"
                           style={{ height: '100%', width: '100%' }}
                        />
                     </a>
                  </Animated>
               </div>
            </div>
         </div>
      </div>
   )
}

export default About
