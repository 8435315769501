import React from 'react'
import Slideshow from './Slideshow'
import partnership_img from '../icons/partnership.jpg'

function Partner() {
   return (
      <div className="partner">
         <div class="container">
            <div class="row align-items-center my-1">
               <div class="col-lg-6">
                  <h2 class="font-weight-light">Partner</h2>
                  <p>
                     Die CE-SYS GmbH Computer & Elektronik Systemhaus 
					 ist offizieller Vertrags- und
                     Servicepartner der&nbsp;
                     <a
                        class="normal-link"
                        href="https://www.wortmann.de/"
                        target="_blank"
                        rel="noopener noreferrer"
                     >
                        Wortmann AG&nbsp;
                     </a>
                     und Partner von&nbsp;
                     <a
                        class="normal-link"
                        href="https://www.microsoft.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                     >
                        Microsoft
                     </a>
                     . Mit der&nbsp;
                     <a
                        class="normal-link"
                        href="https://www.tu-ilmenau.de/"
                        target="_blank"
                        rel="noopener noreferrer"
                     >
                        Technischen Universität Ilmenau&nbsp;
                     </a>
                     verbindet uns eine langjährige intensive Partnerschaft. Die
                     Firmengründer und zahlreiche Mitarbeiter haben hier ihre
                     Wurzeln.
                  </p>
               </div>
               <div class="col-lg-6">
                  <a
                     href="http://www.freepik.com/vectorjuice"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     <img
                        class="partnership-img"
                        src={partnership_img}
                        alt="partnership"
                     />
                  </a>
               </div>
               <Slideshow />
            </div>
         </div>
      </div>
   )
}

export default Partner
